import React from 'react';
import { Link } from 'gatsby';
import useQuerySitenav from '../hooks/useQuerySitenav';
import useQueryFooter from '../hooks/useQueryFooter';
import { Section } from '../components/containers';
import HubspotFormWrapper from './hubspotFormWrapper';
import RoyalsOffice from './royalsOffice';

const Footer = (props) => {
  const { footerContent } = useQueryFooter();
  const navItems = getNav();
  const subscribeForm = getSubscribeForm(footerContent);
  const socialLinks = getSocialLinks(footerContent);
  const emailInfo = getEmailInfo(footerContent);
  const offices = getOffices(footerContent);

  return (
    <>
      <footer data-layout-footer data-use-palette {...props}>
        {subscribeForm}

        <Section className='contact'>
          {emailInfo}
          {offices}
          {socialLinks}

          <aside data-content-type='legals'>
            <small>&copy; The New Royals {new Date().getFullYear()}</small>
            <nav>{navItems}</nav>
          </aside>
        </Section>
      </footer>
    </>
  );
};

const getSubscribeForm = (footerData) => {
  const { subscribeEnabled, subscribe } = footerData;
  const { title, subtitle, portalId, formId, successMessage } = subscribe;

  if (!subscribeEnabled) return null;

  return (
    // <Section className='hubspotForm' halign='left'>
    //   {title && <h3>{title}</h3>}
    //   {subtitle && <p>{subtitle}</p>}
    <HubspotFormWrapper
      portalId={portalId}
      formId={formId}
      successMessage={successMessage}
      title={title}
      subtitle={subtitle}
    />
    // </Section>
  );
};

// ToDO Make social links a component
const getSocialLinks = (footerData) => {
  const { socialEnabled, socialTitle, socialNetworks } = footerData;

  if (!socialEnabled || socialNetworks?.length == 0) return null;
  const items = socialNetworks.map((item) => (
    <li key={item.key}>
      <a
        href={item.url}
        title={`Connect with us on ${item.name}`}
        style={{ '--icon': `url("${item?.icon.asset.url}")` }}
      >
        {item.name}
      </a>
    </li>
  ));

  return (
    <aside data-content-type='social'>
      {socialTitle && <small>{socialTitle}</small>}
      <ul>{items}</ul>
    </aside>
  );
};

const getOffices = (footerData) => {
  const { officesEnabled, officesTitle, offices } = footerData;
  if (!officesEnabled) return null;

  const title = officesTitle && <h1>{officesTitle}</h1>;
  const officeComponents = offices.map((office) => <RoyalsOffice {...office} key={office._id} />);
  return (
    <>
      {title}
      {officeComponents}
    </>
  );
};

const getNav = () => {
  return useQuerySitenav().sitenav.footer.map((item) => {
    const { key, description, label, to } = item;
    return (
      <Link key={key} partiallyActive={true} activeClassName='active' to={to} title={description}>
        {label}
      </Link>
    );
  });
};

const getEmailInfo = (footerData) => {
  const { emailEnabled, emailTitle, emailAddress } = footerData;
  if (!emailEnabled) return null;

  const title = emailTitle && <h1>{emailTitle}</h1>;
  return (
    <>
      {title}
      <address data-content-type='email'>
        <a
          href={`mailTo:${emailAddress}`}
          title={`Open a new email addressed to ${emailAddress} in your default email tool`}
        >
          {emailAddress}
        </a>
      </address>
    </>
  );
};

export default Footer;
