import React, { useState } from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';
import { VERTICAL_ALIGN, HORIZONTAL_ALIGN } from '../constants';
import Grid from './grid';

// ToDo Allow HTML tag for containers to be set via props

const Section = ({
  halign = false,
  children,
  className,
  isMain = false,
  isCompact = false,
  backgroundColour = null,
  padding = null,
  style,
  ...props
}) => {
  const classNames = [];
  const [ref, setRef] = useState();

  const { isIntersecting } = useIntersectionObserver(ref, observerConfig);

  if (halign) classNames.push(`h-${halign}`);
  if (className) classNames.push(className);
  if (!isIntersecting) classNames.push('isHidden');
  if (isMain) classNames.push('isMain');
  if (isCompact) classNames.push('isCompact');
  if (padding) classNames.push(`padding-${padding}`);

  const localStyle = isCompact ? { margin: 0 } : {};
  const backgroundStyle = backgroundColour ? { backgroundColor: backgroundColour } : {};
  const fullStyle = { ...style, ...localStyle, ...backgroundStyle };

  return (
    <section
      ref={setRef}
      className={classNames.join(' ')}
      data-module
      data-layout-section
      data-use-palette
      {...props}
      style={fullStyle}
    >
      {children}
    </section>
  );
};

const Cols = ({
  valign = VERTICAL_ALIGN.CENTER, children, className, isCompact, backgroundColour = null, padding = null, ...props
}) => {
  const classNames = [];
  const [ref, setRef] = useState();
  const { isIntersecting } = useIntersectionObserver(ref, observerConfig);

  if (className) classNames.push(className);
  if (valign) classNames.push(`v-${valign}`);
  if (!isIntersecting) classNames.push('isHidden');
  if (padding) classNames.push(`padding-${padding}`);
  const backgroundStyle = backgroundColour ? { backgroundColor: backgroundColour } : {};

  return (
    <section
      ref={setRef}
      className={classNames.join(' ')}
      data-module
      data-layout-columns
      data-use-palette
      {...props}
      style={backgroundStyle}
    >
      {children}
    </section>
  );
};

const observerConfig = {
  rootMargin: '100% 0px -20% 0px',
};

export { Section, Cols, Grid };
