import React, { useState } from 'react';
import { gridStyles } from './grid.module.scss';

// TODO document the grid configuration options

const Grid = ({
  children, className, config, ...props
}) => {
  const classNames = [gridStyles];
  const [ref, setRef] = useState();

  const customStyles = {};
  if (className) classNames.push(className);

  if (config) {
    config.colspans
      && Object.entries(config.colspans).forEach((colspan) => {
        customStyles[`--col-span-${colspan[0]}`] = colspan[1];
      });
    config.className && classNames.push(config.className);
    config.rowGap && (customStyles['--row-gap'] = config.rowGap);
    config.colGap && (customStyles['--col-gap'] = config.colGap);
  }

  return (
    <section
      ref={setRef}
      className={classNames.join(' ')}
      style={customStyles}
      data-module
      data-layout-grid
      {...props}
    >
      {children}
    </section>
  );
};

export default Grid;
