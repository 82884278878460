import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Section } from './containers';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('We\'ll need a valid email address').required('Required'),
  optIn: Yup.boolean(),
});

const HubspotFormWrapper = ({
  portalId,
  formId,
  title,
  subtitle,
  successMessage = "Thanks! You're on the list",
  ...props
}) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [mailChimpError, setMailChimpError] = useState(null);
  const [mailChimpSuccess, setMailChimpSuccess] = useState(null);
  // console.log('FORM');

  return (
    <Section className="hubspotForm" halign="left" data-use-palette="true">
      {title && <h3>{title}</h3>}
      {subtitle && !mailChimpSuccess && <p>{subtitle}</p>}

      {hasSubmitted && <div>Submitted</div>}
      {mailChimpError && <p className="mailchimperror">{mailChimpError}</p>}
      {mailChimpSuccess && <p>You’re in! We’ll let know when future Everybody Knows editions are released.</p>}

      {mailChimpSuccess === null
        && (
          <Formik
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              optIn: false,
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              setHasSubmitted(false);
              setMailChimpError(null);
              setMailChimpSuccess(null);
              // setTimeout(() => {
              //   alert(JSON.stringify(values, null, 2));
              //   setSubmitting(false);
              // }, 400);
              const fieldnames = {
                FNAME: values.firstName,
                LNAME: values.lastName,
                'group[32826][1]': values.optIn ? 1 : 0,
              };

              addToMailchimp(values.email, fieldnames)
                .then((data) => {
                  // I recommend setting data to React state
                  // but you can do whatever you want (including ignoring this `then()` altogether)
                  console.log(data);
                  setSubmitting(false);
                  setHasSubmitted(false);
                  if (data.result === 'error') setMailChimpError(data.msg);
                  if (data.result === 'success') setMailChimpSuccess(data.msg);
                })
                .catch(() => {
                  // unnecessary because Mailchimp only ever
                  // returns a 200 status code
                  // see below for how to handle errors
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div>

                {isSubmitting && <p>Submitting...</p>}

                <form onSubmit={handleSubmit}>

                  <div className="email">
                    <input
                      name="email"
                      type="email"
                      placeholder="Email*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <label htmlFor="email">
                      {values.email
                        && (
                          <span>
                            Email *
                          </span>
                        )}
                      <span className="error">{errors.email && touched.email && errors.email}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      name="firstName"
                      type="text"
                      placeholder="First name *"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    <label htmlFor="firstName">
                      {values.firstName
                        && (
                          <span>
                            First name *
                          </span>
                        )}
                      <span className="error">{errors.firstName && touched.firstName && errors.firstName}</span>
                    </label>
                  </div>
                  <div>
                    <input
                      name="lastName"
                      type="text"
                      placeholder="Last name*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    <label htmlFor="lastName">
                      {values.lastName
                        && (
                          <span>
                            Last name *
                          </span>
                        )}
                      <span className="error">{errors.lastName && touched.lastName && errors.lastName}</span>
                    </label>
                  </div>
                  <div className="opt-in">
                    <div className="opt-in-fields">
                      <input
                        type="checkbox"
                        name="opt-in"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="opt-in">
                        Opt in for news, updates and other announcements from The Royals.
                        {' '}
                        <span className="error">{errors.optIn && touched.optIn && errors.optIn}</span>
                      </label>
                    </div>
                    <span className="privacy">
                      You can opt out of Everybody Knows and any other
                      communications from The Royals at any time.
                      We’ll keep your information safe, and won’t pass it on to
                      other companies. Read our
                      {' '}
                      <a href="/privacy-policy/"> Privacy Policy</a>
                    </span>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="submit"
                    >
                      Go on, subscribe
                    </button>
                  </div>
                </form>
              </div>

            )}
          </Formik>
        )}

    </Section>
  );
};

export default HubspotFormWrapper;
