import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Section } from './containers';

const Location = ({ children }) => {
  return (
    <Section itemScope itemType='https://schema.org/LocalBusiness'>
      {children}
    </Section>
  );
};

const Address = ({ children }) => {
  return (
    <address itemProp='address' itemScope itemType='https://schema.org/PostalAddress'>
      {children}
    </address>
  );
};

const Name = ({ children }) => (
  <h1 itemProp='name' className='h2'>
    {children}
  </h1>
);

const Image = ({ image, alt = '' }) => {
  const img = image?.asset ? getImage(image.asset) : getImage(image) || null;
  return img && <GatsbyImage itemProp='image' image={img} alt={alt} />;
};

const Phone = ({ value, children }) => (
  <a itemProp='telephone' href={`tel:${value}`}>
    {children}
  </a>
);

const City = ({ children }) => <p itemProp='name'>{children}</p>;
const Street = ({ children }) => <p itemProp='streetAddress'>{children}</p>;
const Suburb = ({ children }) => <span itemProp='addressLocality'>{children}</span>;
const State = ({ children }) => <span itemProp='addressRegion'>{children}</span>;
const Postcode = ({ children }) => <span itemProp='postalCode'>{children}</span>;
const MapLink = ({ url, name = 'View this location on a map', children }) => (
  <a href={url} itemProp='hasMap' title={name}>
    {children}
  </a>
);

Location.Name = Name;
Location.Phone = Phone;
Location.Image = Image;
Address.Name = Name;
Address.City = City;
Address.Street = Street;
Address.Suburb = Suburb;
Address.State = State;
Address.Postcode = Postcode;
Address.MapLink = MapLink;

export { Location, Address };
