import React from 'react';
import { Location, Address } from './location';

const RoyalsOffice = ({ name, image, address, phones, mapLink }) => {
  const { streets, postcode, state, suburb } = address;

  const phoneComponents = phones?.length > 0 && phones.map((phone) => getPhoneComponent(phone));
  const mapLinkElement = mapLink && getMapLinkElement({ mapLink, address });

  return (
    <Location>
      {image && <Location.Image image={image} alt={`${name}`} />}
      <Address>
        {name && <Address.Name>{name}</Address.Name>}
        {streets && <Address.Street>{streets.join(', ')}</Address.Street>}
        <p>
          {suburb && <Address.Suburb>{suburb}</Address.Suburb>}
          {state && <Address.State>{state}</Address.State>}
          {postcode && <Address.Postcode>{postcode}</Address.Postcode>}
        </p>
        {mapLinkElement}
      </Address>
      {phoneComponents}
    </Location>
  );
};

const getAddressAsString = (address) => {
  const { streets, postcode, state, suburb } = address;
  const parts = [];
  const statePostcode =
    state || postcode
      ? `${state && state}${state && postcode && ' '}${postcode && postcode}`
      : null;

  streets && parts.push(streets.join('. '));
  suburb && parts.push(suburb);
  statePostcode && parts.push(statePostcode);

  return parts.join(', ');
};

const getMapLinkElement = ({ mapLink, address }) => {
  if (!mapLink) return null;

  return (
    <Address.MapLink url={mapLink} name={`View ${getAddressAsString(address)} on a map`}>
      View Map
    </Address.MapLink>
  );
};

const getPhoneComponent = ({ title, value, friendlyFormat }) => (
  <Location.Phone value={value} key={value}>
    {title}
    {title && ' '}
    {friendlyFormat}
  </Location.Phone>
);

export default RoyalsOffice;
