import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { SCROLL_DIRECTION } from '../constants';
import useScrollDirection from '../hooks/useScrollDirection';
import useQuerySitenav from '../hooks/useQuerySitenav';
import HamburgerButton from './hamburgerButton';

const Header = () => {
  const [isOpen, setIsOpen] = useState(0);
  const [className, setClassName] = useState('show');

  const newScrollDirection = useScrollDirection();
  const toggleNavState = () => setIsOpen((isOpen) => !isOpen);

  const navItems = useQuerySitenav().sitenav.primary.map((item) => {
    const { key, description, label, to } = item;
    return (
      <li key={key}>
        <Link partiallyActive={true} activeClassName='active' to={to} title={description}>
          {label}
        </Link>
      </li>
    );
  });

  useEffect(() => {
    setClassName(newScrollDirection !== SCROLL_DIRECTION.DOWN ? 'show' : 'hide');
  }, [newScrollDirection]);

  return (
    <header
      data-layout-header
      data-nav-state={isOpen ? 'open' : 'closed'}
      data-use-palette
      className={className}
    >
      <h1>
        <Link to='/'>The Royals</Link>
      </h1>

      <HamburgerButton isOpen={isOpen} onClick={toggleNavState}>
        <HamburgerButton.LabelOpen>Hide Navigation</HamburgerButton.LabelOpen>
        <HamburgerButton.LabelClosed>Show Navigation</HamburgerButton.LabelClosed>
      </HamburgerButton>

      <nav className={isOpen ? 'open' : 'closed'}>
        <ul>{navItems}</ul>
      </nav>
    </header>
  );
};

export default Header;
