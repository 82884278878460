import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import '../css/index.scss';

import Header from './header';
import Footer from './footer';
import SkipToLandmark from './skipToLandmark';

const Layout = ({ children, template = 'none', data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <>
      <SkipToLandmark target='#main'>Skip to content</SkipToLandmark>
      <SkipToLandmark target='#footer'>Skip to end</SkipToLandmark>
      <Header />
      <div>
        <main id='main' data-template={template}>
          {children}
        </main>
        <Footer id='footer' />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
