import { useStaticQuery, graphql } from 'gatsby';

const useQueryFooter = () => {
  return useStaticQuery(
    graphql`
      query {
        footerContent: sanityFooter {
          subscribeEnabled
          subscribe {
            title
            subtitle
            portalId
            formId
            successMessage
          }

          emailEnabled
          emailTitle
          emailAddress

          officesEnabled
          officesTitle
          offices {
            _id
            name
            phones: phone {
              value
              friendlyFormat
            }
            address {
              streets: street
              suburb
              state
              postcode
            }
          }

          socialEnabled
          socialTitle
          socialNetworks {
            key: _id
            name
            url
            icon {
              asset {
                url
                gatsbyImageData
              }
            }
          }
        }
      }
    `
  );
};

export default useQueryFooter;
