import { useStaticQuery, graphql } from 'gatsby';

const useQuerySitenav = () => {
  return useStaticQuery(
    graphql`
      query {
        sitenav: sanitySitenav {
          primary: primaryNav {
            ...callToAction
          }
          footer: footerNav {
            ...callToAction
          }
        }
      }
    `
  );
};

export default useQuerySitenav;
