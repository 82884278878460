export const VERTICAL_ALIGN = {
  TOP: 'top',
  BOTTOM: 'bottom',
  CENTER: 'center',
};

export const HORIZONTAL_ALIGN = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

export const SCROLL_DIRECTION = {
  UP: 'up',
  DOWN: 'down',
  NONE: null,
};

export const DIRECTION = {
  PREV: 'prev',
  NEXT: 'next',
};

export const HERO_TYPE = {
  FULLSCREEN: 'fullscreen',
  DECORATIVE_IMAGE: 'decorative_image',
};

export const LINK_TYPE = {
  MAIL_TO: 'mailTo',
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

export const CASE_STUDY_FILTER = {
  FEATURED: 'featured',
  NON_FEATURED: 'nonFeatured',
  ALL: 'all',
};

export const GRID = {
  FEATURED: {
    className: 'featured',
    colspans: { md: 6, lg: 6, xl: 6 },
  },

  THREE_MAX: {
    className: 'threeMax',
    colspans: { md: 6, lg: 6, xl: 4 },
    rowGap: 'calc(var(--column-spacing-vertical) * 1.5)',
  },

  DEFAULT: {
    colspans: { xs: 6, sm: 4, lg: 3, xl: 3 },
  },
};

export const VIMEO_BASE_URL = 'https://vimeo.com';
